import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import Integration from './style'
import BlogCard from './Components/Card'
import BlogRequlerData from './Data'
import { Images } from '~data'
import SectionTitle from './Components/SectionTitle'
import { useTranslation } from 'react-i18next'
const IntegrationSection = ({ ...rest }) => {
  const { t } = useTranslation()
  return (
    <Integration backgroundColor='#ffffff'>
      <Container>
        <Row className='row justify-content-center'>
          <Col className='col-xl-10'>
            <Integration.Box className='text-center'>
              <SectionTitle
                title={t('portofolio', { ns: 'about' })}
                // text="Create custom landing pages with Fastland that converts <br class='d-none d-sm-block'> more visitors than any website."
              />
            </Integration.Box>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          {BlogRequlerData.map(
            (
              {
                image,
                badge,
                Like,
                date,
                title,
                user,
                commentCount,
                imageWide,
              },
              index,
            ) => {
              return (
                <Col
                  xs='12'
                  className='col-lg-4 col-md-6 col-xs-9'
                  key={'bsp' + index}
                >
                  <BlogCard
                    image={image}
                    imageWide={imageWide}
                    badge={badge}
                    date={date}
                    title={title}
                    user={user}
                    Like={Like}
                    commentCount={commentCount}
                  />
                </Col>
              )
            },
          )}
        </Row>
      </Container>
    </Integration>
  )
}

export default IntegrationSection
