import Images from '~data/imageImports'
const serviceData = {
  services: [
    {
      id: 'ms1',
      icon: Images.marketing.servicesIcon1,
      iconColor: '#ffd166',
      title: 'Manage Smartly',
      text: "Stay on top of your task lists and stay<br class='d-none d-lg-block'> in touch with what's happening",
    },
    {
      id: 'ms2',
      icon: Images.marketing.servicesIcon2,
      iconColor: '#96f7d2',
      title: 'Communicate Fast',
      text: "Stay on top of your task lists and stay<br class='d-none d-lg-block'> in touch with what's happening",
    },
    {
      id: 'ms3',
      icon: Images.marketing.servicesIcon3,
      iconColor: '#a9d2ff',
      title: 'Influence Easily',
      text: "Stay on top of your task lists and stay<br class='d-none d-lg-block'> in touch with what's happening",
    },
  ],
}
export default serviceData
