import styled from 'styled-components/macro'
import { Box, Heading, Button, Paragraph } from '~styled'

const Cta = styled(Box)`
  padding-top: 60px;
  padding-bottom: 100px;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

  @media (min-width: 768px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &::before {
    content: '';
    background-color: #000000;
    opacity: 0.6;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
  }
`
Cta.Title = styled(Heading)`
  font-size: 28px;
  font-weight: 700;
  letter-spacing: normal;

  @media (min-width: 768px) {
    font-size: 35px;
  }

  @media (min-width: 992px) {
    font-size: 45px;
  }
`
Cta.Text = styled(Paragraph)`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.75;
`

Cta.Button = styled(Button)`
  color: #fff !important;
  background-color: #fff;
    border-color: #ff5722;
  box-shadow: -12px 12px 50px rgb(253 52 110 / 30%);
  border-radius: 500px;

  &:hover {
    box-shadow: 0 20px 20px rgb(255 75 96 / 0%);
    color: #fff !important;
  }

  .video-btn {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    color: #fd346e;
    border: 0;
    box-shadow: none;
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 500px;
      content: '';
      background-color: #fff;
      z-index: -1;
      pointer-events: none;
      opacity: 0;
      animation: sonarWave 2s linear infinite;
    }
`
Cta.Box = styled(Box)``

Cta.Image = styled(Box)`
  position: relative;
  border-radius: 10px;
  .video-btn {
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    color: #F47621;
    border: 0;
    box-shadow: none;
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 500px;
      content: '';
      background-color: #fff;
      z-index: -1;
      pointer-events: none;
      opacity: 0;
      animation: sonarWave 2s linear infinite;
    }
    @media (min-width: 576px) {
      min-width: 60px;
      max-width: 60px;
      min-height: 60px;
      max-height: 60px;
    }
  }
`

export default Cta
