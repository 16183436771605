import React from 'react'
import BreadCrumb from './style'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Col, Row, Container } from 'react-bootstrap'
import { SuperTag } from '~components'
import { Images } from '~data'
import { useTranslation } from 'react-i18next'
export default function AboutUsTitle({ colClasses, title, text, ...rest }) {
  const { t } = useTranslation()
  return (
    <BreadCrumb
      style={{
        backgroundColor: '#424242',
        backgroundImage: `url(${Images.AboutUs.titleAboutUs})`,
        // resize: 'stretch',
        width: `100%`,
        height: '100%',
        backgroundSize: '100% 100%',
        // backgroundAttachment: 'fixed',
        // backgroundPosition: 'top',
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
      }}
      // backgroundColor='#f3f4f6'
      {...rest}
    >
      <Container>
        <Row className='row justify-content-center text-center'>
          <Col className={colClasses ? colClasses : 'col-xl-5'}>
            <BreadCrumb.Title as='h2'>
              {t('aboutUs', { ns: 'about' })}
            </BreadCrumb.Title>
          </Col>
        </Row>
      </Container>
    </BreadCrumb>
  )
}
