import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Images } from '~data'
import Cta from './style'
import Video from '~components/VideoModal'

export default function ComproSobat() {
  return (
    <Cta
      style={{
        backgroundImage: `url(${Images.AboutUs.companyProfile})`,
        // backgroundSize: '100% 100%',
      }}
    >
      <Container>
        <Row className='justify-content-center'>
          <Col xs='12' className='col-xl-7 text-center'>
            <Cta.Title as='h2' fontColor='#fff' mb='50px'>
              Company Profile SoBatRp
            </Cta.Title>
            <Cta.Image>
              <Video id='Qq0Fd4EApoY' className='video-btn'>
                {' '}
                <i className='fa fa-play' />{' '}
              </Video>
            </Cta.Image>
          </Col>
        </Row>
      </Container>
    </Cta>
  )
}

// style={{backgroundImage: `url(${})`}}
