import React from 'react'
import { PageWrapper } from '~components/Core'
// import BreadCrumbSection from '~sections/about/BreadCrumb'
// import FeatureSection from '~sections/about/Feature'
// import ServicesSectionTwo from '~sections/about/ServicesTwo'
// import ProcessSection from '~sections/about/Process'
import FooterSix from '~sections/Home/footerSix'
import AboutSection from '~sections/AboutUs/whoSobatrpSection'
import VisionMissionSection from '~sections/AboutUs/visionMissionSection'
import AboutUsTitle from '~sections/AboutUs/titleSection'
import ComproSobat from '~sections/AboutUs/ComproSobat'
import IntegrationSection from '~sections/AboutUs/Integration'
import ServiceSection from '~sections/AboutUs/Service'
import ContactSection from '~sections/AboutUs/ContactSection'
import { graphql } from 'gatsby'

const header = {
  headerClasses:
    'site-header site-header--menu-end dark-header site-header--sticky',
  containerFluid: false,
  // buttonBlock: (
  //   <HeaderButton
  //     as={Link}
  //     to='/survey-request'
  //     className='d-none d-sm-flex'
  //     children='Ajukan Survei'
  //   />
  // ),
  darkLogo: false,
}

export default function About() {
  return (
    <PageWrapper headerConfig={header}>
      {/* <BreadCrumbSection title='Tentang Kami' />
      <FeatureSection />
      <ServicesSectionTwo />
      <ProcessSection /> */}
      <AboutUsTitle />
      <ServiceSection />
      {/* <AboutSection /> */}
      {/* <VisionMissionSection /> */}
      <ComproSobat />
      <IntegrationSection />
      <ContactSection />
      <FooterSix />
    </PageWrapper>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
