import styled from 'styled-components/macro'
import { Box, Heading, Paragraph } from '~styled'

const Service = styled(Box)`
  padding-top: 55px;
  padding-bottom: 55px;
  position: relative;

  img {
    border-radius: 8px;
  }

  @media (min-width: 768px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (min-width: 992px) {
    padding-top: 125px;
    padding-bottom: 113px;
  }
`
Service.Title = styled(Heading)`
  font-weight: 700;
  line-height: 60px;
  font-size: 28px;

  @media (min-width: 768px) {
    font-size: 35px;
  }

  @media (min-width: 992px) {
    font-size: 45px;
  }
`
Service.Shape = styled(Heading)`
  position: absolute;
  top: 26%;
  left: -6%;
  width: 10%;
`
Service.Text = styled(Paragraph)`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 32px;
  color: #424242;
  opacity: 0.8;

  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 32px;
    margin-top: 24px;
  }

  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 32px;
    margin-top: 0;
  }
`
Service.Content = styled(Box)`
  margin-top: 80px;

  @media (min-width: 992px) {
    margin-top: 0;
  }
`
Service.Box = styled(Box)``
export default Service
