import React from 'react'
import Contact from './style'
import SectionTitle from './Components/SectionTitle'
import { Col, Container, Row } from 'react-bootstrap'
export default function ContactOne() {
  return (
    <Contact backgroundColor='#f3f4f6'>
      <Container>
        <Row>
          <Col className='col-xl-7 col-lg-7'>
            {/* <Contact.Box>
              <SectionTitle
                subTitle='Kontak kesini'
                title='Send A Message'
                text="When, while lovely valley teems with vapour around meand <br class='d-non d-lg-block'/>
            meridian the upper impenetrable."
                subTitleProps={{ mb: '10px' }}
                titleProps={{ mb: '10px', as: 'h2' }}
                mb='50px'
              />
            </Contact.Box>
            <Contact.From>
              <form action='./'>
                <Row>
                  <Col xs='12' className='col-lg-6 mb-4'>
                    <div className='form-floating'>
                      <input
                        className='form-control'
                        placeholder='Leave a comment here'
                        id='floatinginput'
                      />
                      <label htmlFor='floatinginput'>Your Email</label>
                    </div>
                  </Col>
                  <Col xs='12' className='col-lg-6 mb-4'>
                    <div className='form-floating'>
                      <input
                        className='form-control'
                        placeholder='Leave a comment here'
                        id='floatinginput2'
                      />
                      <label htmlFor='floatinginput2'>Phone number</label>
                    </div>
                  </Col>
                  <Col xs='12' className='col-lg-12'>
                    <div className='form-floating'>
                      <textarea
                        className='form-control'
                        placeholder='Leave a comment here'
                        id='floatingTextarea3'
                      />
                      <label htmlFor='floatingTextarea3'>
                        Your Message Here{' '}
                      </label>
                    </div>
                  </Col>
                  <Col xs='12' className='col-lg-12'>
                    <Row className='align-items-center mt-3'>
                      <div className='col-md-8 col-lg-7 col-md-6 col-xl-8 pt-3'>
                        <div className='form-check d-flex align-items-center'>
                          <input
                            className='form-check-input bg-white float-none mt-0'
                            type='checkbox'
                            defaultValue
                            id='flexCheckDefault'
                          />
                          <label
                            className='form-check-label'
                            htmlFor='flexCheckDefault'
                          >
                            Your email address will not be published. Required
                            fields are marked *
                          </label>
                        </div>
                      </div>
                      <Col
                        xs='12'
                        className='col-md-4 col-lg-5 col-xl-4 text-md-end pt-3'
                      >
                        <Contact.Button>Send Message</Contact.Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </form>
            </Contact.From> */}
            <div class='mapouter'>
              <div class='gmap_canvas'>
                <iframe
                  width='850'
                  height='750 '
                  id='gmap_canvas'
                  src='https://maps.google.com/maps?q=Tokopedia%20Tower&t=&z=13&ie=UTF8&iwloc=&output=embed'
                  frameborder='0'
                  scrolling='no'
                  marginheight='0'
                  marginwidth='0'
                ></iframe>
                {/* <a href='https://2piratebay.org'>pirate bay</a> */}
                {/* <br>
                <style>.mapouter{position:relative;text-align:right;height:500px;width:640px}
                </style> */}
                {/* <a href='https://www.embedgooglemap.net'>
                  google map link for website
                </a> */}
                {/* <style>.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:640px;}
                </style> */}
              </div>
            </div>
          </Col>
          <Col xs='12' className='col-xl-5 col-lg-5'>
            <Contact.WidgetsBox className='contact-widget-box'>
              <Contact.WidgetsBoxTitle as='h2'>
                Hubungi kami
              </Contact.WidgetsBoxTitle>
              <Contact.WidgetsBoxText as='p'>
                We'd love to hear from you <br className='d-none d-xl-block' />{' '}
              </Contact.WidgetsBoxText>
              <Row>
                <Col xs='12' className='col-lg-12 col-sm-6'>
                  <Contact.Widgets>
                    <Contact.WidgetsIcon>
                      <i className='fas fa-envelope' />
                    </Contact.WidgetsIcon>
                    <Contact.WidgetsBoxBody>
                      <Contact.WidgetsTitle as='h3'>
                        visit us :
                      </Contact.WidgetsTitle>
                      <Contact.WidgetsText as='p'>
                        Tokopedia Tower Lt.19F, <br className='d-block' />{' '}
                        Jakarta Selatan
                      </Contact.WidgetsText>
                    </Contact.WidgetsBoxBody>
                  </Contact.Widgets>
                </Col>
                <Col xs='12' className='col-lg-12 col-sm-6 active'>
                  <Contact.Widgets>
                    <Contact.WidgetsIcon className='active'>
                      <i className='fas fa-phone-alt' />
                    </Contact.WidgetsIcon>
                    <Contact.WidgetsBoxBody>
                      <Contact.WidgetsTitle as='h3'>
                        mail us :
                      </Contact.WidgetsTitle>
                      <Contact.WidgetsText as='p'>
                        <span className='d-block'>
                          <a href='mailto:business@sobatrp.com'>
                            business@sobatrp.com
                          </a>
                        </span>
                      </Contact.WidgetsText>
                    </Contact.WidgetsBoxBody>
                  </Contact.Widgets>
                </Col>
                <Col xs='12' className='col-lg-12 col-sm-6'>
                  <Contact.Widgets>
                    <Contact.WidgetsIcon>
                      <i className='fas fa-map-marker-alt' />
                    </Contact.WidgetsIcon>
                    <Contact.WidgetsBoxBody>
                      <Contact.WidgetsTitle as='h3'>
                        call us :
                      </Contact.WidgetsTitle>
                      <Contact.WidgetsText as='p'>
                        +62 851 7409 8137
                      </Contact.WidgetsText>
                    </Contact.WidgetsBoxBody>
                  </Contact.Widgets>
                </Col>
              </Row>
            </Contact.WidgetsBox>
          </Col>
        </Row>
      </Container>
    </Contact>
  )
}
