import { StaticImage as Img } from 'gatsby-plugin-image'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import serviceData from '~data/marketing/Service'
import ServiceWidget from './Component/Widget'
import Service from './style'

export default function ServiceSection() {
  return (
    <>
      <Service className='border-top border-default-color-2 bg-default'>
        {/* <Service.Shape className='service-shape service-shape--l1'>
          <Img
            src='../../../assets/image/marketing/services-shape-l1.png'
            alt='shape dot'
            layout='fullWidth'
            placeholder='blurred'
          />
        </Service.Shape> */}
        <Container>
          {/* Section Title */}
          {/* <Row className='align-items-end justify-content-center text-start'>
            <Col xs='12' className='col-lg-7 col-md-12 col-xs-10'>
              <Service.Title as='h2' mb='0'>
                Your business needs a
                <br className='d-none d-xs-block d-lg-none d-xl-block' />
                better shape today.
              </Service.Title>
            </Col>
            <Col xs='12' className='col-lg-5 col-md-12 col-xs-10'>
              <Service.Text>
                Create custom landing pages with
                <br className='d-none d-xs-block' /> Fastland that converts more
                visitors
                <br className='d-none d-sm-block' /> than any website. Easy
                &amp; Fast.
              </Service.Text>
            </Col>
          </Row> */}
          <Img
            src='../../../assets/image/aboutUs/2.Tentang SoBatRp.jpg'
            alt='SobatRpMeeting'
            // layout='fullWidth'
            placeholder='blurred'

          />
          <Service.Box mtLG='60px' mtMD='60px' mtXS='24px' mt='24px'>
            {/* <Row className='justify-content-center justify-content-md-start'>
              {serviceData.services.map(
                ({ title, icon, iconColor, text, id }) => {
                  return (
                    <Col className='col-lg-4 col-xs-6 col-10' key={id}>
                      <ServiceWidget
                        icon={icon}
                        title={title}
                        text={text}
                        iconColor={iconColor}
                        id={id}
                        mt='40px'
                      />
                    </Col>
                  )
                },
              )}
              <Col className='col-lg-4 col-xs-6 col-10'></Col>
            </Row> */}
            <Row className='justify-content-center text-start'>
              <Col xs='12' className='col-lg-3 col-md-12 col-xs-12'>
                <Service.Title as='h2' mt='0'>
                  Siapakah <br className='d-none d-xs-none d-md-none d-xl-block' />
                  SoBatRp?
                </Service.Title>
              </Col>
              <Col xs='12' className='col-lg-9 col-md-12 col-xs-12'>
                <Service.Text>
                  Didirikan pada tahun 2022, PT SoBatRp Solusi Terbarukan
                  bertekad untuk mengatasi krisis energi dan masalah lingkungan
                  yang tercemar, serta menyediakan gaya hidup cerdas dengan
                  teknologi canggih.
                  <br />
                  <br className='d-none d-xs-block' /> Kami ingin berkontribusi
                  dengan memanfaatkan teknologi berbasis Energi Terbarukan untuk
                  perumahan. Kami menawarkan skema holistik dalam memberikan
                  solusi terbaik dari ujung ke ujung kepada pelanggan kami.
                  Selain itu, kami menyediakan teknologi peralatan rumah tangga
                  dengan menggunakan sistem Internet of Things (IoT) untuk
                  mengembangkan ekosistem kehidupan rumah yang cerdas.
                  <br />
                  <br className='d-none d-sm-block' /> Dengan SoBatRp,
                  bersama-sama kita menyongsong masa depan untuk dunia yang
                  lebih baik dengan kehidupan yang lebih baik
                </Service.Text>
              </Col>
            </Row>
          </Service.Box>
        </Container>
      </Service>
    </>
  )
}
